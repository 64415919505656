
html,
body,
h1,
h2,
h3,
h4,
h5,
ul,
ol,
dl,
li,
dt,
dd,
p,
div,
span,
img,
a,
table,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 500;
  vertical-align: baseline;
  box-sizing: border-box;
}


article,
header,
footer,
aside,
figure,
figcaption,
nav,
section {
    display: block;
}

button{
    border: 0;
    font: unset;
    background-color: unset;
}


html {
    font-size: 62.5%;
    font-family: HiraKakuPro-W3, 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
    font-weight: 400;
    overflow-y:auto;
    // width: 100vw;
}

_::-webkit-full-page-media, _:future, :root html{
  font-weight: 500;
}


body {
    font-size: 1rem;
    // line-height: 1.67;
        line-height: 1;
    // width: 100vw;
    margin: 0;
    overflow-x: hidden;
    color: $color-black;

    &:first-line {
        line-height: 1;
    }
}

ol,
ul {
    list-style: none;
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}

img{
    height: auto;
}
input[type="email"],
input[type="number"],
input[type="text"]{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    max-width: 100%;
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
}

textarea{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
}

select{
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    box-sizing: content-box;

    &::-ms-expand {
        display: none;
    }
}

button,
input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
}