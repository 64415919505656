@import "mixin";
@import "_library/variables";
@import "_library/animation";
@import "_library/button";
@import "_library/title";

@import "_partials/extend";
@import "_partials/reset";
@import "_partials/header";
@import "_partials/footer";
@import "_partials/nav";
@import "_partials/scroll";
@import "_partials/modal";
@import "_partials/privacypolicy";
@import "_partials/tel";



html{
    color: $color-black;
    font-size: map-get($_font-size, sm);
    font-family: $font-sanserif;
    @include mq(md, min){
        font-size: map-get($_font-size, md);
    }
}
a{
    color: $color-black;
    text-decoration: none;
    transition: $duration;
    display: inline-block;
}

a.disabled{
    pointer-events: none;
    opacity: 0.3;
}

.link--word{
    color:$color-main;
    text-decoration:underline;
}

img{
    @include mq(md, max){
        width: 100%;
    }
}
.inner{
    max-width: 1100px;
    margin: auto;
    @include mq(md, max){
        max-width: 100%;
        padding: 0 7.2%;
    }
}

.main{
    display: block;
}

.only-pc{
    display: block;
    @include mq(sm, max){
        display: none;
    }
}
.only-sp{
    display: none;
    @include mq(sm, max){
        display: block;
    }
}

.link{
    &--tel{
        pointer-events: none;
        @include mq(sm, max){
            pointer-events: inherit;
        }
    }
}

.js-scrollprevent{
    overflow: hidden;
    touch-action:none;
}