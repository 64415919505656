.privacypolicy{
    &__lead{
        font-size: _rem(18px,md);
        text-align: center;
        font-weight: bold;
        padding: 0 0 32px;
        @include mq(sm, max){
            font-size: _rem(18px,sm);
        }
    }
    &__intro{
        font-size: _rem(15px,md);
        line-height: 1.5;
        @include mq(sm, max){
            font-size: _rem(15px,sm);
        }
    }
    &__date{
        font-size: _rem(14px,md);
        line-height: 1.5;
        text-align: right;
        padding: 8px 0 0;
        @include mq(sm, max){
            font-size: _rem(14px,sm);
        }
    }
    &__content{
        font-size: _rem(15px,md);
        font-family: $font-sanserif;
        line-height: 1.5;
        padding: 40px 0 0;
        @include mq(sm, max){
            font-size: _rem(15px,sm);
        }
    }
    &__block{
        padding: 24px 0 0;
    }
    &__title{
        font-weight: bold;
    }
    &__text{
        font-size: _rem(14px,md);
        font-weight: lighter;
        padding: 8px 0 0 1rem;
        @include mq(sm, max){
            font-size: _rem(14px,sm);
        }
    }

}
.section-subtitle {
    @extend .privacypolicy__title;
    font-size: _rem(14px,md);
}

.section-subtitle--2,
.section-subtitle--3 {
    @extend .privacypolicy__title;
    font-size: _rem(14px,md);
}

.section-ollist {
    list-style: decimal;
    li {
        display: list-item;
        font-size: _rem(14px,md);
        line-height: 1.6;
        margin-bottom: 0.5rem;
        font-weight: lighter;
    }
}


.table-privacy .table-body {
    font-size: _rem(14px,md);
    display: table;
    width: 100%;
    padding: 10px 0;
    margin-top: 0;
    margin-bottom: 0;
    dt, dd {
        display: table-cell;
        vertical-align: top;
        width: 100%;
    }
    dt {
        max-width: 180px;
        width: 28%;
    }
    dd {
        font-weight: lighter;
    }

}