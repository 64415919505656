@charset "UTF-8";
.fade-in {
  opacity: 0;
  transform: translate(0, 2rem);
  transition: 0.8s ease-in-out;
}

.fade-in.js-started {
  opacity: 1;
  transform: translate(0, 0);
}

.btn, .btn--normal, .btn--submit, .btn--send, .btn--back {
  position: relative;
  color: #ED406F;
  font-size: 1rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: center;
  width: 300px;
  border: 1px solid #ED406F;
  padding: 19px 0 20px;
}

@media (max-width: 768px) {
  .btn, .btn--normal, .btn--submit, .btn--send, .btn--back {
    display: block;
    margin: auto;
  }
}

@media (max-width: 668px) {
  .btn, .btn--normal, .btn--submit, .btn--send, .btn--back {
    font-size: 1.23077rem;
    width: 100%;
  }
}

.btn svg, .btn--normal svg, .btn--submit svg, .btn--send svg, .btn--back svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
}

.btn svg rect, .btn--normal svg rect, .btn--submit svg rect, .btn--send svg rect, .btn--back svg rect {
  transition: 0.3s ease-in-out;
}

.btn svg polyline, .btn--normal svg polyline, .btn--submit svg polyline, .btn--send svg polyline, .btn--back svg polyline {
  transition: 0.3s ease-in-out;
}

.btn:hover, .btn--normal:hover, .btn--submit:hover, .btn--send:hover, .btn--back:hover {
  color: #ffffff;
  background-color: #ED406F;
}

.btn:hover svg rect, .btn--normal:hover svg rect, .btn--submit:hover svg rect, .btn--send:hover svg rect, .btn--back:hover svg rect {
  fill: #ffffff;
}

.btn:hover svg polyline, .btn--normal:hover svg polyline, .btn--submit:hover svg polyline, .btn--send:hover svg polyline, .btn--back:hover svg polyline {
  stroke: #ffffff;
}

.btn--normal svg {
  right: 10px;
}

.btn--submit {
  position: relative;
  border: 1px solid #ED406F !important;
  background: url("/assets/img/common/icon-arrow.svg") no-repeat center right 10px !important;
  background-size: 32px !important;
  padding: 16px 0 !important;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.btn--submit:hover {
  background: url("/assets/img/common/icon-arrow-wh.svg") no-repeat center right 10px #ED406F !important;
}

.btn--send {
  color: #ffffff;
  position: relative;
  border: 1px solid #ED406F !important;
  background: url("/assets/img/common/icon-arrow-wh.svg") no-repeat center right 10px #ED406F !important;
  background-size: 32px !important;
  margin: 0 0 0 40px;
  padding: 16px 0 !important;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.btn--send:hover {
  border: 1px solid #ED406F !important;
  background: url("/assets/img/common/icon-arrow.svg") no-repeat center right 10px #ffffff !important;
  background-size: 32px !important;
  color: #ED406F;
}

@media (max-width: 768px) {
  .btn--send {
    margin: 32px auto 0;
  }
}

.btn--back svg {
  left: 10px;
  transform: rotate(180deg) translateY(50%);
}

.section__title, .section__title--center, .section__title--right, .section__title--left {
  position: relative;
}

.section__title br, .section__title--center br, .section__title--right br, .section__title--left br {
  display: none;
}

@media (max-width: 668px) {
  .section__title br, .section__title--center br, .section__title--right br, .section__title--left br {
    display: block;
  }
}

.section__title--ja {
  color: #222222;
  font-size: 3.25rem;
  display: block;
  padding: 24px 0 16px;
  letter-spacing: 2.6px;
}

@media (max-width: 668px) {
  .section__title--ja {
    font-size: 2.92308rem;
    line-height: 1.2;
    padding: 16px 0 10px;
  }
}

.section__title--en {
  color: #001516;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.125rem;
  font-weight: lighter;
  letter-spacing: 1.06px;
  display: block;
}

@media (max-width: 668px) {
  .section__title--en {
    font-size: 1.07692rem;
  }
}

.section__title--center {
  text-align: center;
}

.section__title--right {
  text-align: right;
}

.section__title--left {
  text-align: left;
}

.nav__item--tel, .nav__link--tel, .tel__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
ul,
ol,
dl,
li,
dt,
dd,
p,
div,
span,
img,
a,
table,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 500;
  vertical-align: baseline;
  box-sizing: border-box;
}

article,
header,
footer,
aside,
figure,
figcaption,
nav,
section {
  display: block;
}

button {
  border: 0;
  font: unset;
  background-color: unset;
}

html {
  font-size: 62.5%;
  font-family: HiraKakuPro-W3, 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  font-weight: 400;
  overflow-y: auto;
}

_::-webkit-full-page-media, _:future, :root html {
  font-weight: 500;
}

body {
  font-size: 1rem;
  line-height: 1;
  margin: 0;
  overflow-x: hidden;
  color: #222222;
}

body:first-line {
  line-height: 1;
}

ol,
ul {
  list-style: none;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  height: auto;
}

input[type="email"],
input[type="number"],
input[type="text"] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  max-width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
}

select {
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}

select::-ms-expand {
  display: none;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

.header {
  width: 100%;
  z-index: 10;
}

@media (max-width: 768px) {
  .header {
    height: 50px;
    background-color: #ffffff;
  }
}

.header__logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
}

.header__logo__link {
  background-color: #ffffff;
  padding: 20px 19px 20px 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header__logo__link {
    background-color: transparent;
    padding: 12px 0 0 10px;
  }
}

@media (max-width: 768px) {
  .header__logo__img {
    width: 135px;
  }
}

.top .header {
  position: absolute;
  transition: .5s;
}

@media (max-width: 768px) {
  .top .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.top .header.js-fixed-02 {
  transform: translateY(-80px);
}

@media (max-width: 768px) {
  .top .header.js-fixed-02 {
    transform: translateY(0);
  }
}

.top .header.js-fixed {
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(0);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0 0 6px;
}

.pages .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
}

.footer {
  color: #ffffff;
  background-color: #333333;
  padding: 40px 0;
}

@media (max-width: 668px) {
  .footer {
    padding: 32px 0 24px;
  }
}

.footer .inner {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  padding: 0 2.8%;
}

@media (max-width: 768px) {
  .footer .inner {
    padding: 0 7.2%;
  }
}

@media (max-width: 668px) {
  .footer .inner {
    display: block;
  }
}

@media (max-width: 768px) {
  .footer__logo__link img {
    width: 200px;
  }
}

@media (max-width: 668px) {
  .footer__logo__link img {
    width: 135px;
  }
}

.footer__block {
  font-size: 0.875rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.5;
}

@media (max-width: 668px) {
  .footer__block {
    font-size: 1.07692rem;
  }
}

@media (max-width: 320px) {
  .footer__block {
    font-size: 0.92308rem;
  }
}

.footer__block:last-child {
  margin: 0 0 0 auto;
}

@media (max-width: 668px) {
  .footer__block:last-child {
    margin: 8px 0 0;
  }
}

.footer .copyright {
  font-size: 0.75rem;
  text-align: right;
}

@media (max-width: 668px) {
  .footer .copyright {
    font-size: 0.76923rem;
    text-align: center;
  }
}

.footer__info--address {
  padding: 16px 0 14px;
}

@media (max-width: 668px) {
  .footer__info--address {
    padding: 20px 0 10px;
  }
}

.footer__info--number {
  padding: 0 0 24px;
}

@media (max-width: 668px) {
  .footer__info--number {
    padding: 0 0 40px;
  }
}

.footer__info--number a {
  color: #ffffff;
}

.footer__link {
  text-decoration: underline;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.footer__link:hover {
  color: #666666;
}

.nav__icon {
  display: none;
  position: absolute;
  top: 14px;
  right: 15px;
  width: 20px;
  height: 18px;
  transition: all .4s;
  margin: auto;
  z-index: 110;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav__icon {
    display: block;
  }
}

.nav__icon span {
  position: absolute;
  width: 100%;
  height: 2px;
  display: inline-block;
  background: #ED406F;
  right: 0;
  transition: .4s all;
}

.nav__icon span:nth-child(1) {
  top: 0;
  animation: bar01 .75s forwards;
}

.nav__icon span:nth-child(2) {
  top: 8px;
  transition: all .25s .25s;
  opacity: 1;
}

.nav__icon span:nth-child(3) {
  bottom: 0;
  animation: bar03 .75s forwards;
}

.nav__icon.js-open span:nth-child(1) {
  animation: active-bar01 .75s forwards;
}

.nav__icon.js-open span:nth-child(2) {
  opacity: 0;
}

.nav__icon.js-open span:nth-child(3) {
  animation: active-bar03 .75s forwards;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2.8% 0 0;
}

@media (max-width: 768px) {
  .nav {
    display: block;
    padding: 15% 0 0;
  }
}

@media (orientation: landscape) and (max-width: 736px) {
  .nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8% 0 0;
  }
  .nav .nav__item--contact, .nav .nav__item--tel {
    width: 100%;
  }
}

.nav.js-fixed .nav__link {
  color: #222222;
}

.nav.js-fixed .nav__link--tel {
  color: #222222;
}

.nav.js-fixed .nav__item--contact .nav__link {
  border: 2px solid #222222;
}

.nav.js-fixed .nav__item--contact .nav__link:hover {
  border: 2px solid #666666;
}

.nav.js-fixed .nav__item--tel {
  color: #222222;
}

.nav.js-fixed .nav__item--tel svg g {
  fill: #222222;
}

.nav.js-fixed .nav__note--tel {
  color: #222222;
}

.nav__wrapper {
  left: auto;
}

@media (max-width: 768px) {
  .nav__wrapper {
    position: absolute;
    top: 0;
    left: -100vw;
    right: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 100;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }
}

.nav__wrapper.js-open {
  left: auto;
}

@media (max-width: 768px) {
  .nav__wrapper.js-open {
    left: 0;
    background: #ffffff;
    overflow: scroll;
    opacity: 1;
  }
  .nav__wrapper.js-open .nav__item {
    transform: translateY(0);
  }
}

.nav__item {
  padding: 37px 2% 0 0;
}

@media (max-width: 768px) {
  .nav__item {
    font-size: 1.125rem;
    text-align: center;
  }
}

@media (max-width: 668px) {
  .nav__item {
    font-size: 1.38462rem;
  }
}

.nav__item--contact {
  padding: 37px 0 0;
}

@media (max-width: 768px) {
  .nav__item--contact {
    font-size: 1.125rem;
    text-align: center;
  }
}

@media (max-width: 668px) {
  .nav__item--contact {
    font-size: 1.38462rem;
  }
}

.nav__item--contact .nav__link {
  border: 2px solid #222222;
  padding: 10px 30px;
}

@media (max-width: 768px) {
  .nav__item--contact .nav__link {
    color: #ED406F;
    border: 1px solid #ED406F;
    padding: 12px 70px;
  }
}

.nav__item--contact .nav__link:after {
  content: none;
}

.nav__item--contact .nav__link:hover {
  color: #ffffff;
  border: 2px solid #666666;
  background: #666666;
}

.nav__item--tel {
  position: absolute;
  top: 10px;
  right: 2.8%;
}

@media (max-width: 768px) {
  .nav__item--tel {
    position: static;
    display: block;
    padding: 24px 0 0;
  }
}

.nav__item.js-current .nav__link {
  color: #ED406F;
}

.nav__item.js-current .nav__link:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #ED406F;
  transform: scale(1, 1);
  transform-origin: left top;
  transition: transform .3s;
}

.nav__link {
  position: relative;
  color: #222222;
  display: inline-block;
}

@media (max-width: 768px) {
  .nav__link {
    color: #222222;
  }
}

.nav__link:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #666666;
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform .3s;
}

.nav__link:hover {
  color: #ED406F;
}

.nav__link:hover:after {
  transform: scale(1, 1);
}

.nav__link--tel {
  color: #ffffff;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .nav__link--tel {
    color: #222222;
    font-size: 1.25rem;
    text-align: center;
  }
}

@media (max-width: 668px) {
  .nav__link--tel {
    font-size: 1.53846rem;
  }
}

.nav__link--tel svg {
  width: 10px;
  height: 10px;
}

@media (max-width: 768px) {
  .nav__link--tel svg {
    width: 18px;
    height: 17px;
  }
}

.nav__link--tel svg g {
  fill: #ffffff;
}

@media (max-width: 768px) {
  .nav__link--tel svg g {
    fill: #222222;
  }
}

.nav__link--tel span {
  display: block;
  padding: 0 4px;
}

.nav__note--tel {
  color: #ffffff;
  font-size: 0.6875rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (max-width: 768px) {
  .nav__note--tel {
    color: #222222;
    font-size: 0.875rem;
    text-align: center;
    padding: 14px 0 0;
  }
}

@media (max-width: 668px) {
  .nav__note--tel {
    font-size: 1.07692rem;
  }
}

.pages .nav__item--contact .nav__link {
  border: 2px solid #222222;
}

@media (max-width: 768px) {
  .pages .nav__item--contact .nav__link {
    color: #ED406F;
    border: 1px solid #ED406F;
  }
}

.pages .nav__item--contact .nav__link:hover {
  border: 2px solid #666666;
}

.pages .nav__link {
  color: #222222;
}

.pages .nav__link--tel {
  color: #222222;
}

.pages .nav__link--tel svg g {
  fill: #222222;
}

.pages .nav__note--tel {
  color: #222222;
}

@media all and (-ms-high-contrast: none) {
  .nav__item--tel {
    align-items: flex-end;
  }
}

@keyframes bar01 {
  0% {
    transform: translateY(16px) rotate(45deg);
  }
  50% {
    transform: translateY(16px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes bar03 {
  0% {
    transform: translateY(-16px) rotate(-45deg);
  }
  50% {
    transform: translateY(-16px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes active-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(16px) rotate(0);
  }
  100% {
    transform: translateY(6px) rotate(45deg);
  }
}

@keyframes active-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-16px) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}

.scroll {
  position: absolute;
  color: #ED406F;
  bottom: 0;
  right: 50%;
  left: 50%;
  z-index: 2;
}

@media (max-width: 668px) {
  .scroll {
    bottom: 25px;
  }
}

.scroll p {
  text-align: end;
  font-size: 0.875rem;
  transform: rotate(90deg);
}

@media (max-width: 668px) {
  .scroll p {
    font-size: 1.07692rem;
  }
}

.scroll__arrow {
  width: 1px;
  height: 60px;
  margin: 60px auto 0;
  background-color: transparent;
  position: relative;
  overflow: hidden;
}

.scroll__arrow::before {
  content: '';
  width: 1px;
  height: 60px;
  margin: 60px auto 0;
  background-color: #ED406F;
  position: absolute;
  top: -120px;
  left: 0;
  -webkit-animation: arrow 2s ease 0s infinite normal;
  animation: arrow 2s ease 0s infinite normal;
}

@keyframes arrow {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  60% {
    -webkit-transform: translate3d(-50%, c, 0);
    transform: translate3d(-50%, 60px, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 60px, 0);
    transform: translate3d(-50%, 60px, 0);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9998;
  display: none;
  overflow-y: scroll;
}

.modal__link {
  cursor: pointer;
}

.modal__item {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 99999;
  display: none;
  transform: translate(-50%, 0);
}

@media (max-width: 768px) {
  .modal__item {
    width: 90%;
  }
}

.modal__content {
  max-width: 1100px;
  background-color: #ffffff;
  margin: 80px auto;
  padding: 40px;
  overflow-y: scroll;
}

@media (max-width: 668px) {
  .modal__content {
    padding: 40px 30px;
  }
}

.icon--close {
  position: absolute;
  top: 60px;
  right: 0;
}

.icon--close span {
  position: absolute;
  right: 15px;
  width: 25px;
  height: 2px;
  display: inline-block;
  background-color: #ffffff;
  box-sizing: border-box;
  transition: all .2s;
  cursor: pointer;
}

.icon--close span:nth-of-type(1) {
  top: 0;
  right: 8px;
  -webkit-transform: rotate(-45deg) translateY(0px);
  transform: rotate(-45deg) translateY(0px);
}

.icon--close span:nth-of-type(2) {
  top: 0;
  right: 8px;
  -webkit-transform: rotate(45deg) translateY(0px);
  transform: rotate(45deg) translateY(0px);
}

.privacypolicy__lead {
  font-size: 1.125rem;
  text-align: center;
  font-weight: bold;
  padding: 0 0 32px;
}

@media (max-width: 668px) {
  .privacypolicy__lead {
    font-size: 1.38462rem;
  }
}

.privacypolicy__intro {
  font-size: 0.9375rem;
  line-height: 1.5;
}

@media (max-width: 668px) {
  .privacypolicy__intro {
    font-size: 1.15385rem;
  }
}

.privacypolicy__date {
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: right;
  padding: 8px 0 0;
}

@media (max-width: 668px) {
  .privacypolicy__date {
    font-size: 1.07692rem;
  }
}

.privacypolicy__content {
  font-size: 0.9375rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.5;
  padding: 40px 0 0;
}

@media (max-width: 668px) {
  .privacypolicy__content {
    font-size: 1.15385rem;
  }
}

.privacypolicy__block {
  padding: 24px 0 0;
}

.privacypolicy__title, .section-subtitle, .section-subtitle--2,
.section-subtitle--3 {
  font-weight: bold;
}

.privacypolicy__text {
  font-size: 0.875rem;
  font-weight: lighter;
  padding: 8px 0 0 1rem;
}

@media (max-width: 668px) {
  .privacypolicy__text {
    font-size: 1.07692rem;
  }
}

.section-subtitle {
  font-size: 0.875rem;
}

.section-subtitle--2,
.section-subtitle--3 {
  font-size: 0.875rem;
}

.section-ollist {
  list-style: decimal;
}

.section-ollist li {
  display: list-item;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  font-weight: lighter;
}

.table-privacy .table-body {
  font-size: 0.875rem;
  display: table;
  width: 100%;
  padding: 10px 0;
  margin-top: 0;
  margin-bottom: 0;
}

.table-privacy .table-body dt, .table-privacy .table-body dd {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.table-privacy .table-body dt {
  max-width: 180px;
  width: 28%;
}

.table-privacy .table-body dd {
  font-weight: lighter;
}

.tel__link {
  font-size: 2.5rem;
  letter-spacing: 2.35px;
}

@media (max-width: 668px) {
  .tel__link {
    font-size: 2.46154rem;
    letter-spacing: 1.88px;
  }
}

@media (max-width: 320px) {
  .tel__link {
    font-size: 1.84615rem;
  }
}

.tel__link span {
  display: block;
  margin: 0 0 0 16px;
}

@media (max-width: 668px) {
  .tel__link span {
    margin: 0 0 0 8px;
  }
}

@media (max-width: 668px) {
  .tel__link svg {
    width: 24px;
    height: 23px;
  }
}

.tel__note {
  font-size: 1rem;
  text-align: center;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  padding: 16px 0 0;
}

@media (max-width: 668px) {
  .tel__note {
    font-size: 1.07692rem;
  }
}

@media (max-width: 320px) {
  .tel__note {
    font-size: 0.92308rem;
  }
}

html {
  color: #222222;
  font-size: 13px;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (min-width: 769px) {
  html {
    font-size: 16px;
  }
}

a {
  color: #222222;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  display: inline-block;
}

a.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.link--word {
  color: #ED406F;
  text-decoration: underline;
}

@media (max-width: 768px) {
  img {
    width: 100%;
  }
}

.inner {
  max-width: 1100px;
  margin: auto;
}

@media (max-width: 768px) {
  .inner {
    max-width: 100%;
    padding: 0 7.2%;
  }
}

.main {
  display: block;
}

.only-pc {
  display: block;
}

@media (max-width: 668px) {
  .only-pc {
    display: none;
  }
}

.only-sp {
  display: none;
}

@media (max-width: 668px) {
  .only-sp {
    display: block;
  }
}

.link--tel {
  pointer-events: none;
}

@media (max-width: 668px) {
  .link--tel {
    pointer-events: inherit;
  }
}

.js-scrollprevent {
  overflow: hidden;
  touch-action: none;
}
