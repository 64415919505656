.nav__icon{
    display: none;
    @include mq(md, max){
        display: block;
    }
    position: absolute;
    top: 14px;
    right: 15px;
    width: 20px;
    height: 18px;
    transition: all .4s;
    margin: auto;
    z-index: 110;
    cursor: pointer;
    span{
        position: absolute;
        width: 100%;
        height: 2px;
        display: inline-block;
        background: $color-main;
        right: 0;
        transition: .4s all;
        &:nth-child(1){
            top: 0;
            animation: bar01 .75s forwards;
        }
        &:nth-child(2){
            top: 8px;
            transition: all .25s .25s;
            opacity: 1;
        }
        &:nth-child(3){
            bottom: 0;
            animation: bar03 .75s forwards;
        }
    }
    &.js-open{
        span{
            &:nth-child(1){
                animation: active-bar01 .75s forwards;
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                animation: active-bar03 .75s forwards;
            }
        }
    }
}
.nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2.8% 0 0;
    @include mq(md, max){
        display: block;
        padding: 15% 0 0;
    }
    @media (orientation: landscape) and (max-width:736px){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8% 0 0;
        .nav__item{
            &--contact,
            &--tel{
                width: 100%;
            }
        }
    }
    &.js-fixed{
        .nav__link{
            color: $color-black;
            &--tel{
                color: $color-black;
            }
        }
        .nav__item{
            &--contact{
                .nav__link{
                    border: 2px solid $color-black;
                    &:hover{
                        border: 2px solid $color-secondary;
                    }
                }
            }
            &--tel{
                color: $color-black;
                svg{
                    g{
                        fill: $color-black;
                    }
                }
            }
        }

        .nav__note{
            &--tel{
                color: $color-black;
            }
        }
    }
    &__wrapper{
        left: auto;
        @include mq(md, max){
            position: absolute;
            top: 0;
            left: -100vw;
            right: 0;
            width: 100vw;
            min-height: 100vh;
            z-index: 100;
            opacity: 0;
            transition:  opacity .4s ease-in-out;
        }
        &.js-open{
            left: auto;
            @include mq(md, max){
                left: 0;
                background: $color-white;
                overflow: scroll;
                opacity: 1;
                .nav__item{
                    transform: translateY(0);
                }
            }
        }
    }
    &__item{
        padding: 37px 2% 0 0;
        @include mq(md, max){
            font-size: _rem(18px,md);
            text-align: center;
        }
        @include mq(sm, max){
            font-size: _rem(18px,sm);
        }
        &--contact{
            padding: 37px 0 0;
            @include mq(md, max){
                font-size: _rem(18px,md);
                text-align: center;
            }
            @include mq(sm, max){
                font-size: _rem(18px,sm);
            }
            .nav__link{
                border: 2px solid $color-black;
                padding: 10px 30px;
                @include mq(md, max){
                    color: $color-main;
                    border: 1px solid $color-main;
                    padding: 12px 70px;
                }
                &:after{
                    content: none;
                }
                &:hover{
                    color: $color-white;
                    border: 2px solid $color-secondary;
                    background: $color-secondary;
                }
            }
        }
        &--tel{
            @extend %flexcenter;
            position: absolute;
            top: 10px;
            right: 2.8%;
            @include mq(md, max){
                position: static;
                display: block;
                padding: 24px 0 0;
            }
        }
        &.js-current{
            .nav__link{
                color: $color-main;
                &:after{
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $color-main;
                    transform: scale(1, 1);
                    transform-origin: left top;
                    transition: transform .3s;
                }
            }
        }
    }
    &__link{
        position: relative;
        color: $color-black;
        display: inline-block;
        @include mq(md, max){
            color: $color-black;
        }
        &:after{
            position: absolute;
            bottom: -8px;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: $color-secondary;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform .3s;
        }
        &:hover{
            color: $color-main;
            &:after{
                transform: scale(1, 1);
            }
        }
        &--tel{
            @extend %flexcenter;
            color: $color-white;
            font-size: _rem(14px,md);
            @include mq(md, max){
                color: $color-black;
                font-size: _rem(20px,md);
                text-align: center;
            }
            @include mq(sm, max){
                font-size: _rem(20px,sm);
            }
            svg{
                width: 10px;
                height: 10px;
                @include mq(md, max){
                    width: 18px;
                    height: 17px;
                }
                g{
                    fill: $color-white;
                    @include mq(md, max){
                        fill: $color-black;
                    }
                }
            }
            span{
                display: block;
                padding: 0 4px;
            }
        }
    }
    &__note{
        &--tel{
            color: $color-white;
            font-size: _rem(11px,md);
            font-family: $font-sanserif;
            @include mq(md, max){
                color: $color-black;
                font-size: _rem(14px,md);
                text-align: center;
                padding: 14px 0 0;
            }
            @include mq(sm, max){
                font-size: _rem(14px,sm);
            }
        }
    }
}

.pages{
    .nav{
        &__item{
            &--contact{
                .nav__link{
                    border: 2px solid $color-black;
                    @include mq(md, max){
                        color: $color-main;
                        border: 1px solid $color-main;
                    }
                    &:hover{
                        border: 2px solid $color-secondary;
                    }
                }
            }
        }
        &__link{
            color: $color-black;
            &--tel{
                color: $color-black;
                svg{
                    g{
                        fill: $color-black;
                    }
                }
            }

        }
        &__note{
            &--tel{
                color: $color-black;
            }
        }
    }
}


@media all and (-ms-high-contrast: none) {
    .nav__item--tel{
        align-items: flex-end;
    }
}


@keyframes bar01 {
    0% {
        transform: translateY(16px) rotate(45deg);
    }
    50% {
        transform: translateY(16px) rotate(0);
    }
    100% {
        transform: translateY(0) rotate(0);
    }
}

@keyframes bar03 {
    0% {
        transform: translateY(-16px) rotate(-45deg);
    }
    50% {
        transform: translateY(-16px) rotate(0);
    }
    100% {
        transform: translateY(0) rotate(0);
    }
}

@keyframes active-bar01 {
    0% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(16px) rotate(0);
    }
    100% {
        transform: translateY(6px) rotate(45deg);
    }
}
@keyframes active-bar03 {
    0% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(-16px) rotate(0);
    }
    100% {
        transform: translateY(-10px) rotate(-45deg);
    }
}