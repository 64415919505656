.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .8);
    width: 100%;
    height: 100%;
    z-index: 9998;
    display: none;
    overflow-y: scroll;
}

.modal{
    &__link{
        cursor: pointer;
    }
    &__item{
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 99999;
        display: none;
        transform: translate(-50%,0);
        @include mq(md, max){
            width: 90%;
        }
    }
    &__content{
        max-width: 1100px;
        background-color: $color-white;
        margin: 80px auto;
        padding: 40px;
        overflow-y: scroll;
        @include mq(sm, max){
            padding: 40px 30px;
        }
    }
}


.icon--close {
    position: absolute;
    top: 60px;
    right: 0;
    span{
        position: absolute;
        right: 15px;
        width: 25px;
        height: 2px;
        display: inline-block;
        background-color: $color-white;
        box-sizing: border-box;
        transition: all .2s;
        cursor: pointer;
        &:nth-of-type(1){
            top: 0;
            right: 8px;
            -webkit-transform: rotate(-45deg) translateY(0px);
            transform: rotate(-45deg) translateY(0px);
        }
        &:nth-of-type(2) {
            top: 0;
            right: 8px;
            -webkit-transform: rotate(45deg) translateY(0px);
            transform: rotate(45deg) translateY(0px);
        }
    }
}