.scroll{
    position: absolute;
    color: $color-main;
    bottom: 0;
    // right: 0;
    right: 50%;
    left: 50%;
    z-index: 2;
    @include mq(sm, max){
        // right: -10%;
        bottom: 25px;
    }
    p{
        text-align: end;
        font-size: _rem(14px,md);
        transform: rotate(90deg);
        @include mq(sm, max){
            font-size: _rem(14px,sm);
        }
    }
    &__arrow{
        width: 1px;
        height: 60px;
        margin: 60px auto 0;
        background-color: transparent;
        position: relative;
        overflow: hidden;
        &::before{
            content: '';
            width: 1px;
            height: 60px;
            margin: 60px auto 0;
            background-color: $color-main;
            position: absolute;
            top: -120px;
            left: 0;
            -webkit-animation: arrow 2s ease 0s infinite normal;
            animation: arrow 2s ease 0s infinite normal;
        }
    }
}

@keyframes arrow {
    0% {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
    }

    60% {
        -webkit-transform: translate3d(-50%, c, 0);
        transform: translate3d(-50%, 60px, 0);
    }

    100% {
        -webkit-transform: translate3d(-50%, 60px, 0);
        transform: translate3d(-50%, 60px, 0);
    }
}