.section{
    &__title{
        position: relative;
        br{
            display: none;
            @include mq(sm, max){
                display: block;
            }
        }
        // &:before{
        //     content: '';
        //     width: 190px;
        //     height: 1px;
        //     display: block;
        //     background-color: $color-secondary;
        //     @include mq(sm, max){
        //         width: 120px;
        //     }
        // }
        &--ja{
            color: $color-black;
            font-size: _rem(52px,md);
            display: block;
            padding: 24px 0 16px;
            letter-spacing: 2.6px;
            @include mq(sm, max){
                font-size: _rem(38px,sm);
                line-height: 1.2;
                padding: 16px 0 10px;
            }
        }
        &--en{
            color: #001516;
            font-family: $font-sanserif;
            font-size: _rem(18px,md);
            font-weight: lighter;
            letter-spacing: 1.06px;
            display: block;
            @include mq(sm, max){
                font-size: _rem(14px,sm);
            }
        }
        &--center{
            @extend .section__title;
            text-align: center;
            // &:before{
            //     margin: auto;
            // }
        }
        &--right{
            @extend .section__title;
            text-align: right;
            // &:before{
            //     margin: 0 0 0 auto;
            // }
        }
        &--left{
            @extend .section__title;
            text-align: left;
            // &:before{
            //     margin: 0 auto 0 0;
            // }
        }
    }
}