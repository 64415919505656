.footer{
    color: $color-white;
    background-color: $color-footer;
    padding: 40px 0;
    @include mq(sm, max){
        padding: 32px 0 24px;
    }
    .inner{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        max-width: 100%;
        padding: 0 2.8%;
        @include mq(md, max){
            padding: 0 7.2%;
        }
        @include mq(sm, max){
            display: block;
        }
    }
    &__logo{
        &__link{
            img{
                @include mq(md, max){
                    width: 200px;
                }
                @include mq(sm, max){
                    width: 135px;
                }
            }
        }
    }
    &__block{
        font-size: _rem(14px,md);
        font-family: $font-sanserif;
        line-height: 1.5;
        @include mq(sm, max){
            font-size: _rem(14px,sm);
        }
        @include mq(320px, max){
            font-size: _rem(12px,sm);
        }
        &:last-child{
            margin: 0 0 0 auto;
            @include mq(sm, max){
                margin: 8px 0 0;
            }
        }
    }
    .copyright{
        font-size: _rem(12px,md);
        text-align: right;
        @include mq(sm, max){
            font-size: _rem(10px,sm);
            text-align: center;
        }
    }
    &__info{
        &--address{
            padding: 16px 0 14px;
            @include mq(sm, max){
                padding: 20px 0 10px;
            }
        }
        &--number{
            // pointer-events: none;
            padding: 0 0 24px;
            @include mq(sm, max){
                padding: 0 0 40px;
            }
            a{
                color: $color-white;
            }
        }
    }
    &__link{
        text-decoration: underline;
        transition: $duration;
        cursor: pointer;
        &:hover{
            color: $color-secondary;
        }
    }
}