.tel{
    &__link{
        @extend %flexcenter;
        font-size: _rem(40px,md);
        letter-spacing: 2.35px;
        @include mq(sm, max){
            font-size: _rem(32px,sm);
            letter-spacing: 1.88px;
        }
        @include mq(320px, max){
            font-size: _rem(24px,sm);
        }
        span{
            display: block;
            margin: 0 0 0 16px;
            @include mq(sm, max){
                margin: 0 0 0 8px;
            }
        }
        svg{
            @include mq(sm, max){
                width: 24px;
                height: 23px;
            }
        }
    }
    &__note{
        font-size: _rem(16px,md);
        text-align: center;
        font-family: $font-sanserif;
        padding: 16px 0 0;
        @include mq(sm, max){
            font-size: _rem(14px,sm);
        }
        @include mq(320px, max){
            font-size: _rem(12px,sm);
        }

    }
}
