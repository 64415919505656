.btn{
    position: relative;
    color: $color-main;
    font-size: _rem(16px,md);
    font-family: $font-sanserif;
    text-align: center;
    width: 300px;
    border: 1px solid $color-main;
    padding: 19px 0 20px;
    @include mq(md, max){
        display: block;
        margin: auto;
    }
    @include mq(sm, max){
        font-size: _rem(16px,sm);
        width: 100%;
    }
    svg{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: $duration;
        rect{
            transition: $duration;
        }
        polyline{
            transition: $duration;
        }
    }
    &:hover{
        color: $color-white;
        background-color: $color-main;
        svg{
            rect{
                fill: $color-white;
            }
            polyline{
                stroke: $color-white;
            }
        }
    }
    &--normal{
        @extend .btn;
        svg{
            right: 10px;
        }
    }
    &--submit{
        @extend .btn;
        position: relative;
        border: 1px solid $color-main !important;
        background: url($dir+'/common/icon-arrow.svg') no-repeat center right 10px !important;
        background-size: 32px !important;
        padding: 16px 0 !important;
        cursor: pointer;
        transition: $duration;
        &:hover{
            background: url($dir+'/common/icon-arrow-wh.svg') no-repeat center right 10px $color-main !important;
        }
    }
    &--send{
        @extend .btn;
        color: $color-white;
        position: relative;
        border: 1px solid $color-main !important;
        background: url($dir+'/common/icon-arrow-wh.svg') no-repeat center right 10px $color-main !important;
        background-size: 32px !important;
        margin: 0 0 0 40px;
        padding: 16px 0 !important;
        cursor: pointer;
        transition: $duration;
        &:hover{
            border: 1px solid $color-main !important;
            background: url($dir+'/common/icon-arrow.svg') no-repeat center right 10px $color-white !important;
            background-size: 32px !important;
            color: $color-main;
        }
        @include mq(md, max){
            margin: 32px auto 0;
        }

    }
    &--back{
        @extend .btn;
        svg{
            left: 10px;
            transform: rotate(180deg) translateY(50%);
        }
    }
}