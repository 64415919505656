.header{
    width: 100%;
    z-index: 10;
    @include mq(md, max){
        height: 50px;
        background-color: $color-white;
    }
    &__logo{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 110;
        &__link{
            background-color: $color-white;
            padding: 20px 19px 20px 20px;
            cursor: pointer;
            @include mq(md, max){
                background-color: transparent;
                padding: 12px 0 0 10px;
            }
        }
        &__img{
            @include mq(md, max){
                width: 135px;
            }
        }
    }
}
.top{
    .header{
        position: absolute;
        transition: .5s;

        @include mq(md, max){
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
        &.js-fixed-02{
            transform: translateY(-80px);
            @include mq(md, max){
                transform: translateY(0);
            }
        }
        &.js-fixed{
            position: fixed;
            left: 0;
            right: 0;
            transform: translateY(0);
            background-color: rgba(255,255,255,.95);
            padding: 0 0 6px;
        }
    }
}
.pages{
    .header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(255,255,255,.95);
    }
}