$dir: '/assets/img';
$color-white: #ffffff;
$color-black: #222222;
$color-gray-border: #cccccc;
$color-gray-bg: #f5f5f5;

$color-main:#ED406F;
$color-secondary:#666666;
$color-lighter:rgba($color-main, 0.3);
$color-footer:#333333;

$color-green-darker:#005b5f;
$color-green: #00939a;
$color-green-lighter:#b6e0e2;
$color-red: #c00000;

$duration: .3s ease-in-out;

$font-serif: 'Noto Serif JP', '游明朝', YuMincho, 'Hiragino Mincho ProN W3', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
$font-sanserif: 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
